import styled from "styled-components";
import MuiPaper from "@mui/material/Paper";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffc300;
`;

export const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  border-radius: 10px;
`;

export const PaperForm = styled(MuiPaper)`
  width: 100%;
  padding: 20px;
  max-width: 355px;
  border-radius: 10px !important;
  background-color: #dcdcdc;
  > a {
    margin-left: 10px;
    font-size: 12px;
    text-decoration: none;
    color: #ffc300;
  }
`;
