import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithFaceBook,
  signInWithGoogle,
} from "../../auth/firebase";
import { Container, PaperForm, RegisterContainer } from "./styled";
import "./style.css";

const Register: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, email, password);
  };

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
  }, [user, loading]);

  return (
    <Container>
      <PaperForm elevation={12}>
        <RegisterContainer>
          <input
            type="text"
            className="register__textBox"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nome Completo"
          />
          <input
            type="text"
            className="register__textBox"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Endereço de email"
          />
          <input
            type="password"
            className="register__textBox"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Senha"
          />
          <button className="register__btn" onClick={register}>
            Criar uma conta
          </button>
          <button
            className="register__btn register__google"
            onClick={signInWithGoogle}
          >
            Criar uma conta usando o Google
          </button>
          <button
            className="register__btn register__facebook"
            onClick={signInWithFaceBook}
          >
            Criar uma conta usando o Facebook
          </button>

          <div>
            já tem uma conta? <Link to="/">Acesse</Link> agora.
          </div>
        </RegisterContainer>
      </PaperForm>
    </Container>
  );
};

export default Register;
