import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordReset } from "../../auth/firebase";
import { Container, PaperForm, ResetContainer } from "./styled";
import "./style.css";

const Reset: React.FC = () => {
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
  }, [user, loading]);

  return (
    <Container>
      <PaperForm elevation={12}>
        <ResetContainer>
          <input
            type="text"
            className="reset__textBox"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Endereço de email"
          />
          <button
            className="reset__btn"
            onClick={() => sendPasswordReset(email)}
          >
            Enviar email de redefinição de senha
          </button>

          <div>
            Ainda não tem conta? <Link to="/register">Faça </Link> uma agora.
          </div>
        </ResetContainer>
      </PaperForm>
    </Container>
  );
};

export default Reset;
