import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
import axios from "axios";
import swal from "sweetalert";

const firebaseConfig = {
  apiKey: "AIzaSyDQvVxl_A6MK54vSNaq7XNFCMUi16Uz-Yc",
  authDomain: "deedmanager-be803.firebaseapp.com",
  databaseURL: "https://deedmanager-be803-default-rtdb.firebaseio.com",
  projectId: "deedmanager-be803",
  storageBucket: "deedmanager-be803.appspot.com",
  messagingSenderId: "1043970785140",
  appId: "1:1043970785140:web:5b2f93fd3c40fffb2df621",
  measurementId: "G-6YB42RE917",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const signInWithGoogle = async () => {
  const geolocation = await axios.get("https://geolocation-db.com/json/");
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        geolocation: geolocation.data,
      });
    }
  } catch (err) {
    let errorMessage = "Falha ao fazer algo excepcional";
    if (err instanceof Error) {
      errorMessage = err.message;
    }
    swal({
      title: errorMessage,
      icon: "warning",
      dangerMode: true,
    });
  }
};

const signInWithFaceBook = async () => {
  const geolocation = await axios.get("https://geolocation-db.com/json/");
  try {
    const res = await signInWithPopup(auth, facebookProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "facebook",
        email: user.email,
        geolocation: geolocation.data,
      });
    }
  } catch (err) {
    let errorMessage = "Falha ao fazer algo excepcional";
    if (err instanceof Error) {
      errorMessage = err.message;
    }
    swal({
      title: errorMessage,
      icon: "warning",
      dangerMode: true,
    });
  }
};

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    let errorMessage = "Falha ao fazer algo excepcional";
    if (err instanceof Error) {
      errorMessage = err.message;
    }
    swal({
      title: errorMessage,
      icon: "warning",
      dangerMode: true,
    });
  }
};

const registerWithEmailAndPassword = async (
  name: string,
  email: string,
  password: string
) => {
  const geolocation = await axios.get("https://geolocation-db.com/json/");

  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
      geolocation: geolocation.data,
    });
  } catch (err) {
    let errorMessage = "Falha ao fazer algo excepcional";
    if (err instanceof Error) {
      errorMessage = err.message;
    }
    swal({
      title: errorMessage,
      icon: "warning",
      dangerMode: true,
    });
  }
};

const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    swal({
      title: "Link de redefinição de senha enviado!",
      icon: "warning",
      dangerMode: false,
    });
  } catch (err) {
    let errorMessage = "Falha ao fazer algo excepcional";
    if (err instanceof Error) {
      errorMessage = err.message;
    }
    swal({
      title: errorMessage,
      icon: "warning",
      dangerMode: true,
    });
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  signInWithGoogle,
  signInWithFaceBook,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
