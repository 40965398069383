import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  logInWithEmailAndPassword,
  signInWithFaceBook,
  signInWithGoogle,
} from "../../auth/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Container, LoginContainer, PaperForm } from "./styled";
import "./style.css";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/dashboard");
  }, [user, loading]);

  return (
    <Container>
      <PaperForm elevation={12}>
        <LoginContainer>
          <input
            type="text"
            className="login__textBox"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Endereço de email"
          />
          <input
            type="password"
            className="login__textBox"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Senha"
          />
          <button
            className="login__btn"
            onClick={() => logInWithEmailAndPassword(email, password)}
          >
            Login
          </button>
          <button
            className="login__btn login__google"
            onClick={signInWithGoogle}
          >
            Login with Google
          </button>
          <button
            className="login__btn login__facebook"
            onClick={signInWithFaceBook}
          >
            Login with Facebook
          </button>
          <div>
            <Link to="/reset">Esqueceu sua senha?</Link>
          </div>
          <div>
            Ainda não tem conta? <Link to="/register">Faça </Link> uma agora.
          </div>
        </LoginContainer>
      </PaperForm>
    </Container>
  );
};

export default Login;
